import * as React from "react";
import { useState, useRef } from "react";
import emailjs from "emailjs-com";
import "../styles/style.css";

// markup
const ContactPage = () => {
  const toggleNav = () => {
    const mobileNav = document.getElementById("mobileNav");
    mobileNav.classList.toggle("visible");
  };

  const [emailSent, setEmailSent] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const form = useRef(false);

  function sendEmail(e) {
    e.preventDefault();
    setLoadingEmail(true);

    emailjs
      .sendForm(
        "service_fwzu945",
        "template_bz5z0wt",
        e.target,
        "user_iqZq3tOn1Pwa6LIIFggcb"
      )
      .then(
        (result) => {
          setEmailSent(true);
          setLoadingEmail(false);
          form.current.reset();
        },
        (error) => {
          setEmailError(true);
        }
      );
  }
  return (
    <main>
      <title>Alexandra Rusu - Despre mine</title>
      <header>
        <span>Dr. Alexandra Rusu</span>
        <nav>
          <a href="/">Prima pagina</a>
          <a href="/about">Despre mine</a>
          <a href="/cases">Cazuri</a>
          <a href="/treatment">Tratamente și tarife</a>
          <a href="/contact">Contact</a>
        </nav>
        <button className="menu-trigger" onClick={toggleNav}>
          Menu
        </button>
      </header>
      <div className="mobile-nav" id="mobileNav">
        <nav>
          <a href="/">Prima pagina</a>
          <a href="/about">Despre mine</a>
          <a href="/cases">Cazuri</a>
          <a href="/treatment">Tratamente și tarife</a>
          <a href="/contact">Contact</a>
        </nav>
      </div>
      <section className="contact-page" id="contact">
        <h1>Contact</h1>
        <div className="contact-details">
          <div className="data">
            <p>Alexandra Rusu</p>
            <p>0740 455 053</p>
          </div>
          <div className="form">
            <form onSubmit={sendEmail} ref={form}>
              <div className="form-control">
                <label htmlFor="name">Nume</label>
                <input name="name" type="text" required />
              </div>
              <div className="form-control">
                <label htmlFor="email">Email</label>
                <input name="email" type="email" required />
              </div>
              <div className="form-control message">
                <label htmlFor="message">Mesaj</label>
                <textarea name="message" required></textarea>
              </div>
              <button type="submit">Trimite mesaj</button>
            </form>
            {emailSent && (
              <p>
                Mesajul a fost trimis! <span role="img">🚀</span>
              </p>
            )}
            {emailError && (
              <p>
                Mesajul nu s-a putut trimite. Scrie-mi pe{" "}
                <a href="mailto:rusualexandra1990@gmail.com">
                  rusualexandra1990@gmail.com
                </a>
              </p>
            )}
            {loadingEmail && <div className="loading"></div>}
          </div>
        </div>
      </section>
      <footer>
        <div className="col">
          <h3>
            Alexandra Rusu
            <br />
            <span>— medic specialist ortodontie</span>
            <br />
            <small>© 2021</small>
          </h3>
        </div>
        <div className="col">
          <a href="#contact">Contact</a>
        </div>
      </footer>
    </main>
  );
};

export default ContactPage;
